import { zodResolver } from '@hookform/resolvers/zod'
import { ColumnDef } from '@tanstack/react-table'
import to from 'await-to-js'
import { Checkbox } from 'components/FormUtils/Checkbox'
import { ErrorLabel } from 'components/FormUtils/ErrorLabel'
import Select from 'components/FormUtils/Select'
import Papa from 'papaparse'
import { useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import apiService from 'services/api'
import { handleFormError } from 'utils/handleFormError'
import { z } from 'zod'
import { ClientDataTable } from '../../ClientDataTable/ClientDataTable'
import BaseStep from './BaseStep'
import { useImportContactContext } from './ImportContactContext'
import * as S from './styles'

export type ColumnAssignment = {
  columnHeader: string
  sampleData?: string
  contactProperty?: string
  exclude: boolean
}

const columnAssignment = z
  .object({
    columns: z.array(
      z
        .object({
          columnHeader: z.string(),
          contactProperty: z.string(),
          exclude: z.boolean(),
        })
        .partial()
    ),
    nonFieldErrors: z.string(),
  })
  .superRefine((v, ctx) => {
    // Don't allow duplicate contact properties
    if (v.columns === undefined || v.columns.length === 0) return true

    // Validate required contact property
    const requiredContactProperties = ['first_name', 'last_name']
    const missingContactProperties = requiredContactProperties.filter(
      (requiredContactProperty) => {
        return !v.columns.find(
          (column) =>
            column.contactProperty === requiredContactProperty &&
            !column.exclude
        )
      }
    )
    if (missingContactProperties.length > 0) {
      const contactProperties = missingContactProperties.map(
        (contactProperty) => {
          return CONTACT_PROPERTIES_OPTIONS.find(
            (option) => option.value === contactProperty
          )?.label
        }
      )
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Missing required contact properties: ${contactProperties.join(', ')}`,
        path: ['nonFieldErrors'],
      })
    }

    // Validate company OR company domain
    const companyColumn = v.columns.find(
      (column) => column.contactProperty === 'company' && !column.exclude
    )
    const companyDomainColumn = v.columns.find(
      (column) => column.contactProperty === 'company_domain' && !column.exclude
    )
    if (!companyColumn && !companyDomainColumn) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Must have Company or Company Domain column assigned`,
        path: ['nonFieldErrors'],
      })
    }

    for (let i = 0; i < v.columns.length; i++) {
      const column = v.columns[i]
      if (column.exclude) continue // Skip excluded columns
      // Validate contact property not empty
      if (column.contactProperty === undefined && !column.exclude) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Assign or exclude this column`,
          path: ['columns', i, 'contactProperty'],
        })
        continue
      }

      // Ignore notes as it allows duplicates
      if (column.contactProperty === 'notes') continue

      // Validate duplicate contact property
      const otherColumns = v.columns.filter((c, index) => index !== i)
      const otherColumn = otherColumns.find(
        (c) => c.contactProperty === column.contactProperty && !c.exclude
      )
      if (otherColumn) {
        const contactProperty = CONTACT_PROPERTIES_OPTIONS.find(
          (option) => option.value === column.contactProperty
        )?.label

        if (contactProperty) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `Duplicate contact property: ${contactProperty}`,
            path: ['columns', i, 'contactProperty'],
          })
        }
      }
    }
    return true
  })

const CONTACT_PROPERTIES_OPTIONS = [
  { label: 'First Name', value: 'first_name' },
  { label: 'Last Name', value: 'last_name' },
  { label: 'Company', value: 'company' },
  { label: 'Company Domain', value: 'company_domain' },
  { label: 'Work Email', value: 'email_work' },
  { label: 'Personal Email', value: 'email_personal' },
  { label: 'Other Email', value: 'email_generic' },
  { label: 'Phone', value: 'phone_number' },
  { label: 'Title', value: 'title' },
  { label: 'Type', value: 'type' },
  { label: 'Website', value: 'url' },
  { label: 'LinkedIn', value: 'linkedin_url' },
  { label: 'Notes', value: 'notes' },
  { label: 'Contact Labels', value: 'contact_tags' },
  { label: 'Company Tag', value: 'company_tags' },
  { label: 'First Bite ID', value: 'firstbite_id' },
]

export function ColumnsStep() {
  const {
    methods: {
      handleClose,
      prevStep,
      nextStep,
      setPreviewData,
      setColumnAssignment,
      setUnmatchedCompanies,
    },
    state: { file, parsedFile },
  } = useImportContactContext()

  if (!parsedFile || !file) return null

  const parsedFileToColumns = (
    parsedFile: Papa.ParseResult<Record<string, string>>
  ) => {
    if (!parsedFile.meta.fields) return []

    const columns = parsedFile.meta.fields.map((field) => {
      const getFirstNotEmptyFieldValue = (field: string) => {
        const value = parsedFile.data.find(
          (row) => row[field] && row[field].trim().length > 0
        )?.[field]
        return value
      }

      return {
        columnHeader: field,
        sampleData: getFirstNotEmptyFieldValue(field),
        contactProperty: undefined,
        exclude: false,
      }
    })

    return columns
  }
  const data: ColumnAssignment[] = useMemo(
    () => parsedFileToColumns(parsedFile),
    [parsedFile]
  )

  const api = apiService()

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isSubmitting },
    setError,
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(columnAssignment),
    defaultValues: { columns: data, nonFieldErrors: '' },
    mode: 'onChange',
  })

  useEffect(() => {
    const availableContactProperties = CONTACT_PROPERTIES_OPTIONS.slice()

    const findContactProperty = (columnHeader: string) => {
      const contactProperty = availableContactProperties.find(
        (contactProperty) => {
          return (
            contactProperty.label.toLowerCase().trim() ===
            columnHeader.toLowerCase().trim()
          )
        }
      )
      if (!contactProperty) return undefined

      availableContactProperties.splice(
        availableContactProperties.indexOf(contactProperty),
        1
      )
      return contactProperty.value
    }

    // Set initial contact properties values
    if (data) {
      for (let i = 0; i < data.length; i++) {
        const column = data[i]
        setValue(
          `columns.${i}.contactProperty`,
          findContactProperty(column.columnHeader)
        )
      }
    }
  }, [data])

  const watchForm = useWatch({ control })

  useEffect(() => {
    // Retriggers validation for Duplicate error
    if (isDirty && errors && errors.columns) {
      for (let i = 0; i < errors.columns.length!; i++) {
        const columnError = errors.columns[i]
        if (columnError && columnError.contactProperty) {
          if (columnError.contactProperty.message?.includes('Duplicate')) {
            trigger(`columns.${i}.contactProperty`)
          }
        }
      }
    }
  }, [watchForm, isDirty, trigger, errors])

  const columns = useMemo<ColumnDef<ColumnAssignment, any>[]>(
    () => [
      { header: 'Column Header', accessorKey: 'columnHeader' },
      { header: 'Sample Data', accessorKey: 'sampleData' },
      {
        header: 'Contact Property',
        accessorKey: 'contactProperty',
        size: 200,
        cell: (cell) => {
          const index = cell.row.index
          return (
            <Select
              valueWhenCleared={''}
              control={control}
              name={`columns.${index}.contactProperty`}
              options={CONTACT_PROPERTIES_OPTIONS}
              className="w-[200px] mt-0"
            />
          )
        },
      },
      {
        header: 'Exclude',
        accessorKey: 'exclude',
        size: 80,
        cell: (cell) => {
          const index = cell.row.index
          return (
            <div>
              <Checkbox control={control} name={`columns.${index}.exclude`} />
            </div>
          )
        },
      },
    ],
    [data]
  )

  const onSubmit = handleSubmit(
    async (data) => {
      const assignedColumns = data.columns.filter(
        (column) => column.contactProperty && !column.exclude
      )
      const [err, response] = await to(
        api.previewContactsImport(file, assignedColumns)
      )
      if (err) {
        handleFormError(err, setError)
        return
      }

      setColumnAssignment(assignedColumns)
      if (
        response.unmatched_companies &&
        response.unmatched_companies.length > 0
      ) {
        setUnmatchedCompanies(response.unmatched_companies)
        nextStep()
      } else {
        setUnmatchedCompanies([])
        nextStep(1)
      }

      setPreviewData(response)
    },
    (err) => console.warn(err)
  )

  return (
    <BaseStep
      handleClose={handleClose}
      onBack={prevStep}
      onContinue={onSubmit}
      isContinueLoading={isSubmitting}
      title="Update New Contacts - Assign Columns"
      backButtonText="Back"
    >
      <S.Content>
        <S.ContentTitle>
          Confirm or assign each column header to a contact property in First
          Bite. If a field does not exist in First Bite, you can add to Notes or
          exclude from import.
        </S.ContentTitle>
        <form className={'mb-4'} onSubmit={onSubmit}>
          <ClientDataTable
            columns={columns}
            data={data}
            height={'500px'}
            style={{ overflow: 'auto' }}
          />
        </form>
        {errors.nonFieldErrors && errors.nonFieldErrors.message && (
          <ErrorLabel message={errors.nonFieldErrors.message} />
        )}
      </S.Content>
    </BaseStep>
  )
}
