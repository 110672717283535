import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import apiService from 'services/api'
import {
  CARNEGIE_CLASSIFICATION_SIZE_OPTIONS,
  CARNEGIE_CLASSIFICATION_STUDENT_COMPOSITION_OPTIONS,
  CARNEGIE_CLASSIFICATION_YEAR_OPTIONS,
} from 'utils/carnegieClassificationSize'
import { US_CITIES } from 'utils/us_cities'
import { US_STATES } from '../../../../utils/us_states'
import { filterStoreRepo } from '../../../Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from '../../../Filters/components/FilterModal'
import { RangeFilter } from '../../../Filters/fields/RangeFilter'
import { SelectFilter } from '../../../Filters/fields/SelectFilter'

import { FilterIdentifier } from 'models/saved_view'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { UNIVERSITY_COLUMNS } from '../universityColumnsInfo'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'

export function UniversitiesFilterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  // NOTE: disabling 'with none' option for now for food management companies.
  const { data: foodCompanyManagementOptions } =
    api.useGetUniversityFoodManagementCompanyOptions(false)
  const { data: campaignOptions } = api.useGetCampaignsOptions('education-cu')

  const universitiesFilterStore = filterStoreRepo.getStore(filterIdentifier)

  const universityFilterHelper = createFilterColumnHelper({
    columns: UNIVERSITY_COLUMNS,
  })

  return (
    <FilterModal identifier={filterIdentifier} store={universitiesFilterStore}>
      <CommonFiltersetAccountGroup
        filterStore={universitiesFilterStore}
        campaignOptions={campaignOptions}
      />
      <FilterGroup title="Campus Details">
        <RangeFilter
          {...universityFilterHelper.retrieveProps('dormitory_capacity')}
          store={universitiesFilterStore}
        />
        <SelectFilter
          {...universityFilterHelper.retrieveProps('meal_plan_provided')}
          options={[
            { value: '1', label: 'Yes' },
            { value: '0', label: 'No' },
          ]}
          filterStore={universitiesFilterStore}
        />
        <RangeFilter
          {...universityFilterHelper.retrieveProps('estimated_meals_per_day')}
          store={universitiesFilterStore}
        />
        <div className="grid grid-cols-2 gap-2 col-span-3">
          <SelectFilter
            {...universityFilterHelper.retrieveProps('food_management_company')}
            options={
              foodCompanyManagementOptions?.food_management_companies as any
            }
            filterStore={universitiesFilterStore}
          />
          <SelectFilter
            {...universityFilterHelper.retrieveProps(
              'food_management_company_source'
            )}
            options={[
              { label: 'Direct', value: 'direct' },
              { label: 'Indirect', value: 'indirect' },
            ]}
            filterStore={universitiesFilterStore}
          />
          <SelectFilter
            {...universityFilterHelper.retrieveProps(
              'food_management_company_parent'
            )}
            options={
              foodCompanyManagementOptions?.parent_food_management_companies as any
            }
            filterStore={universitiesFilterStore}
          />
        </div>
      </FilterGroup>
      <FilterGroup title="Carnegie Classification">
        <SelectFilter
          {...universityFilterHelper.retrieveProps(
            'carnegie_classification_2021_size_setting_size'
          )}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={CARNEGIE_CLASSIFICATION_SIZE_OPTIONS as any}
          filterStore={universitiesFilterStore}
        />
        <SelectFilter
          {...universityFilterHelper.retrieveProps(
            'carnegie_classification_2021_size_setting_year'
          )}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={CARNEGIE_CLASSIFICATION_YEAR_OPTIONS as any}
          filterStore={universitiesFilterStore}
        />
        <SelectFilter
          {...universityFilterHelper.retrieveProps(
            'carnegie_classification_2021_size_setting_student_composition'
          )}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={CARNEGIE_CLASSIFICATION_STUDENT_COMPOSITION_OPTIONS as any}
          filterStore={universitiesFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Location">
        <SelectFilter
          {...universityFilterHelper.retrieveProps('city')}
          options={US_CITIES}
          filterStore={universitiesFilterStore}
        />
        <SelectFilter
          {...universityFilterHelper.retrieveProps('state_abbr')}
          options={US_STATES}
          filterStore={universitiesFilterStore}
        />
        <ZipcodeFilter filterStore={universitiesFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
