interface Colors {
  [key: number]: string[]
}

export default function getJobStatus(id: number) {
  const colors: Colors = {
    0: ['#D1D5DB', '#000000'], // Submitted (Gray)
    5: ['#D1D5DB', '#000000'], // Processing (Gray)
    10: ['#A4CAFE', '#000000'], // Started (Blue)
    20: ['#FCE96A', '#000000'], // Pending Approval (Yellow)
    30: ['#84E1BC', '#000000'], // Accepted (Green)
    40: ['#F98080', '#000000'], // Rejected (Red)
  }

  return colors[id]
}
