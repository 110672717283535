import { useQuery } from '@tanstack/react-query'
import { ColumnDef } from '@tanstack/react-table'
import to from 'await-to-js'
import TableButtonAction from 'components/Buttons/TableButtons/TableButtonAction'
import {
  DataTable,
  DataTableProvider,
  useDataTableContext,
} from 'components/DataTable'
import { IColumnVisibility } from 'components/DataTable/types'
import FbButton from 'components/FbUI/FbButton'
import {
  filterStoreRepo,
  useFilterParams,
} from 'components/Filters/FilterStore'
import { FilterChips } from 'components/Filters/components/FilterChips/FilterChips'
import TagAssignModal from 'components/Modals/AssignmentModal/TagAssignModal'
import { ColumnSelectorRecipient } from 'components/Modals/ColumnModal/ColumnModal'
import ContactExportModal from 'components/Modals/ExportModal/ContactExportModal'
import dialogConfirm from 'components/dialogConfirm'
import { ContactCompany } from 'models/companies'
import { ContactResponse } from 'models/contacts'
import React, { useEffect, useMemo, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { BsDownload, BsTags } from 'react-icons/bs'
import { toast } from 'react-toastify'
import apiService from 'services/api'
import { ColumnsStoreProvider } from 'stores/ColumnsStore/ColumnsStoreProvider'
import styled from 'styled-components'
import { usePaginationURLParams } from 'utils/usePaginationURLParams'
import { FilterIdentifier } from '../../../models/saved_view'
import { getTableQueryKey } from '../../../utils/getTableQueryKey'
import { ActionButtons } from '../../Buttons/ActionButtons'
import { dataTableSortingStoreRepo } from '../../DataTable/DataTableSorting/DataTableSortingStore'
import { DataTableContainer } from '../../DataTable/UI'
import { TableSearch } from '../../Filters/TableSearch'
import EditContactModal from '../../Modals/EditContactModal'
import * as S from '../CommonTable.styles'
import ContactsFilterset from './ContactsFilterset'
import { useContactsTableColumns } from './useContactsTableColumns'

const tooltip = <Tooltip id="tooltip">Download limited to 10,000 rows</Tooltip>

type SortableFields = (keyof ContactResponse | string)[]

export type ContactsTableRef = {
  refetch: () => void
}

const sortableFields: SortableFields = [
  'email',
  'id',
  'contact_company',
  'name',
  'title',
  'type',
  'notes',
  'url',
  'sales_stage',
  'account_owners',
  'contact_labels',
  'company_tags',
  'phone_number',
  'deal_count',
  'sales_stages',
  'company_size',
  'company_cuisine_50',
  'created',
  'modified',
]

const PAGE_SIZE = 100

interface ContactsTableProps {
  setTotalRowsCount?: (count: number) => void
  myContactsOnly?: boolean
  handleContactUpdated?: () => void
  forCompany?: ContactCompany
  disableUrlPagination?: boolean
  disableExportButton?: boolean
  tableKey: string
  filterIdentifierModifier?: string
  baseFilters?: Record<string, any>
}

function ContactsTableComponent(props: ContactsTableProps) {
  const api = apiService()
  const IDENTIFIER = 'CONTACTS_TABLE' + (props.filterIdentifierModifier ?? '')
  const contactsFilterStore = filterStoreRepo.getStore(
    IDENTIFIER as FilterIdentifier
  )

  const { sorting } = dataTableSortingStoreRepo.getStore(props.tableKey)()
  const {
    state: { totalSelectedRows },
    methods: { clearSelectedRows, setTotalRowsInBackend },
  } = useDataTableContext()

  const [pagination, setPagination] = props.disableUrlPagination
    ? useState({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
      })
    : usePaginationURLParams(PAGE_SIZE)

  const [showExportModal, setShowExportModal] = useState(false)
  const [showAssignContactTags, setShowAssignContactTags] = useState(false)
  const [showAssignCompanyTags, setShowAssignCompanyTags] = useState(false)
  const [showEditContactModal, setShowEditContactModal] =
    React.useState<ContactResponse | null>(null)

  const filtersAsParams = {
    ...useFilterParams(contactsFilterStore),
    ...props.baseFilters,
  }

  const sortParams = useMemo(() => {
    const params: Record<string, unknown> = {}

    if (sorting?.length) {
      params['sort'] = sorting[0].desc ? '-' + sorting[0]?.id : sorting[0]?.id
    }

    return params
  }, [sorting])

  const filterAndSortParams = useMemo(
    () => ({
      ...filtersAsParams,
      ...sortParams,
      company: props.forCompany?.id,
      chain: props.forCompany?.chain,
    }),
    [filtersAsParams, sortParams]
  )

  const TABLE_QUERY_KEY = getTableQueryKey({
    tableKey: props.tableKey,
    filterParams: filterAndSortParams,
    page: pagination.pageIndex + 1,
  })

  // DATA FETCHING
  const { isFetching, data, refetch } = useQuery({
    queryKey: TABLE_QUERY_KEY,
    queryFn: async ({ signal }) => {
      clearSelectedRows()

      if (props.myContactsOnly) {
        return await api.getMyContactsList(
          { ...filterAndSortParams, page: pagination.pageIndex + 1 },
          signal
        )
      } else {
        return await api.getContactsList(
          { ...filterAndSortParams, page: pagination.pageIndex + 1 },
          signal
        )
      }
    },
  })
  // END DATA FETCHING

  const columns = [
    ...(useContactsTableColumns() as any),
    {
      id: 'actions',
      header: 'Actions',
      size: 120,
      meta: {
        isEditable: false,
        headerDisableOrdering: true,
        isLastColumn: true,
        rightAlign: true,
      },
      cell: (info) => {
        const contact = info.row.original
        const contactId = contact.id
        const name = `${contact.first_name} ${contact.last_name}`

        return (
          <ActionButtons
            className={'justify-start'}
            onDelete={() => handleContactDelete(contactId, name)}
            onEdit={() => setShowEditContactModal(contact)}
          />
        )
      },
    },
  ] satisfies ColumnDef<ContactResponse, unknown>[]

  const handleContactDelete = async (contactId: number, name: string) => {
    if (
      await dialogConfirm(
        <>
          Are you sure you want to delete <b>{name}</b>? This action cannot be
          undone.
        </>,
        'Delete Contact?',
        '',
        { confirmLabel: 'Delete' }
      )
    ) {
      const [err] = await to(api.deleteContact(contactId))
      if (!err) {
        toast.success('Contact deleted successfully')
        refetch()
      }
    }
  }

  useEffect(() => {
    clearSelectedRows()
    if (data?.count) {
      props.setTotalRowsCount?.(data.count)
      setTotalRowsInBackend(data.count)
    }
  }, [data])

  return (
    <DataTableContainer>
      <TagAssignModal
        show={showAssignContactTags}
        tagParent="contacts"
        handleClose={() => setShowAssignContactTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        tableObjAccessor="tags"
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={api.contactsBulkEditContactTags}
        idAccessor="id"
      />

      <TagAssignModal
        show={showAssignCompanyTags}
        tagParent="products"
        handleClose={() => setShowAssignCompanyTags(false)}
        tableQueryKey={TABLE_QUERY_KEY}
        data={data?.results ?? []}
        tableObjAccessor="tags"
        filterAndSortParams={filterAndSortParams}
        apiTagsAction={api.bulkTagsFromContacts}
        idAccessor="id"
      />

      {showEditContactModal && (
        <EditContactModal
          show={!!showEditContactModal}
          handleClose={() => setShowEditContactModal(null)}
          onContactUpdated={props.handleContactUpdated}
          contact={showEditContactModal}
          forCompany={props.forCompany}
        />
      )}

      <ContactExportModal
        count={data?.count ?? 0}
        show={showExportModal}
        _handleClose={() => setShowExportModal(false)}
        filters={filterAndSortParams}
      />

      <DataTable
        key={props.tableKey}
        tableHeader={
          <S.SearchContainer>
            <S.SearchContainerFilters>
              <S.TableSearchContainer>
                <TableSearch
                  filterStore={contactsFilterStore}
                  searchPlaceholder="Search by name, company..."
                />
              </S.TableSearchContainer>
              <S.TableButtonsContainer>
                <ContactsFilterset
                  filterIdentifier={IDENTIFIER as FilterIdentifier}
                />
                <ColumnSelectorRecipient tableKey={props.tableKey} />
                <TableButtonAction
                  items={[
                    {
                      label: 'Manage Contact Labels',
                      icon: <BsTags size={20} />,
                      callback: () => setShowAssignContactTags(true),
                    },
                    {
                      label: 'Manage Company Tags',
                      icon: <BsTags size={20} />,
                      callback: () => setShowAssignCompanyTags(true),
                    },
                  ]}
                  disabled={!totalSelectedRows}
                  selectedRowsCount={totalSelectedRows ?? 0}
                />
              </S.TableButtonsContainer>
            </S.SearchContainerFilters>
            <FilterChips
              identifier={'CONTACTS_TABLE'}
              store={contactsFilterStore}
              clearAllButton
              showActive
            />
          </S.SearchContainer>
        }
        tableKey={props.tableKey}
        loading={isFetching}
        data={data?.results ?? []}
        columns={columns}
        sortableFields={sortableFields}
        enableRowSelection
        virtualizeRows={false}
        isPaginationEnabled={true}
        stickyLastColumn
        paginationOptions={{
          pageCount: Math.ceil((data?.count ?? 0) / PAGE_SIZE),
          setPagination: setPagination,
          pagination: pagination,
          isPaginationLoading: isFetching,
        }}
        selectAllText={'Select Contacts'}
        footerControls={
          !props.disableExportButton ? (
            <OverlayTrigger placement="top" overlay={tooltip}>
              <ExportButton
                variant="secondary"
                onClick={() => setShowExportModal(true)}
              >
                <BsDownload size={18} />
              </ExportButton>
            </OverlayTrigger>
          ) : undefined
        }
      />
    </DataTableContainer>
  )
}

ContactsTableComponent.displayName = 'ContactsTable'

export function ContactsTable(
  props: ContactsTableProps & {
    defaultColumnVisibility?: IColumnVisibility<any>
  }
) {
  const { tableKey, defaultColumnVisibility, ...rest } = props

  return (
    <ColumnsStoreProvider
      tableKey={tableKey}
      defaultColumnVisibility={defaultColumnVisibility}
      preferredGroupOrder={[
        'Contact Information',
        'Activity',
        'Account Details',
      ]}
    >
      <DataTableProvider tableKey={tableKey}>
        <ContactsTableComponent tableKey={tableKey} {...rest} />
      </DataTableProvider>
    </ColumnsStoreProvider>
  )
}

const ExportButton = styled(FbButton)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;

  gap: 8px;

  height: 44px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`
