import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import {
  ContactCountRangeFilter,
  DaysSinceLastUpdateRangeFilter,
  NoteCountRangeFilter,
  RangeFilter,
} from 'components/Filters/fields/RangeFilter'
import { SelectFilter } from 'components/Filters/fields/SelectFilter'
import { useCategorySubcategoryOptions } from 'hooks/useCategorySubcategoryFilterOptions'
import apiService from 'services/api'

import { FilterIdentifier } from 'models/saved_view'
import { filterStoreRepo } from '../../Filters/FilterStore'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { DEAL_COLUMNS } from 'components/DataExpansionComponents/Deals/dealsColumnInfo'

export function DealsFilterset(props: { filterIdentifier: FilterIdentifier }) {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: tagsOptions } = api.useGetTagsOptions(true)
  const { data: productsOptions } = api.useGetProductsOptions()
  const { data: distributorOptions } =
    api.useGetCompanyDistributorsOptions(true)
  const { data: contactTagsOptions } = api.useGetTagsOptions(true, 'contacts')
  const dealsFilterStore = filterStoreRepo.getStore(props.filterIdentifier)
  const { categoriesOptions, subCategoriesOptions } =
    useCategorySubcategoryOptions({ filterStore: dealsFilterStore })
  const { data: campaignOptions } = api.useGetCampaignsOptions()

  const dealFilterHelper = createFilterColumnHelper({ columns: DEAL_COLUMNS })

  return (
    <FilterModal store={dealsFilterStore} identifier={props.filterIdentifier}>
      <FilterGroup title={'Opportunity Details'}>
        <RangeFilter
          {...dealFilterHelper.retrieveProps('company_size')}
          store={dealsFilterStore}
        />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('category')}
          options={categoriesOptions}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('sub_category')}
          options={subCategoriesOptions}
          filterStore={dealsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Account">
        <SelectFilter
          {...dealFilterHelper.retrieveProps('product')}
          options={productsOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('sales_stage')}
          options={stageOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('account_owner')}
          options={userOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('taglist')}
          options={tagsOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('contact_tags')}
          options={contactTagsOptions as any}
          filterStore={dealsFilterStore}
        />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('distributors')}
          options={distributorOptions as any}
          filterStore={dealsFilterStore}
        />
        <NoteCountRangeFilter store={dealsFilterStore} />
        <ContactCountRangeFilter store={dealsFilterStore} />
        <SelectFilter
          {...dealFilterHelper.retrieveProps('origin_campaign')}
          filterKey="campaign"
          options={campaignOptions as any}
          filterStore={dealsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Predicted Values">
        <RangeFilter
          {...dealFilterHelper.retrieveProps('predicted_monthly_volume')}
          decimalScale={1}
          store={dealsFilterStore}
        />
        <RangeFilter
          {...dealFilterHelper.retrieveProps('predicted_monthly_revenue')}
          store={dealsFilterStore}
        />
        <DateRangeFilter
          {...dealFilterHelper.retrieveProps('expected_days_to_close')}
          filterStore={dealsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Overrides">
        <RangeFilter
          {...dealFilterHelper.retrieveProps('monthly_volume_override')}
          decimalScale={1}
          store={dealsFilterStore}
        />
        <RangeFilter
          {...dealFilterHelper.retrieveProps('monthly_revenue_override')}
          store={dealsFilterStore}
        />
        <DateRangeFilter
          {...dealFilterHelper.retrieveProps('close_date_override')}
          filterStore={dealsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="User Activity">
        <DateRangeFilter
          {...dealFilterHelper.retrieveProps('modified')}
          filterStore={dealsFilterStore}
        />
        <DateRangeFilter
          {...dealFilterHelper.retrieveProps('created')}
          filterStore={dealsFilterStore}
        />
        <RangeFilter
          {...dealFilterHelper.retrieveProps('last_datetime_stage_changed')}
          decimalScale={1}
          store={dealsFilterStore}
          minAllowNegative={false}
          maxAllowNegative={false}
        />
        <DaysSinceLastUpdateRangeFilter store={dealsFilterStore} />
      </FilterGroup>
    </FilterModal>
  )
}
