import { filterStoreRepo } from 'components/Filters/FilterStore'
import FilterModal, {
  FilterGroup,
} from 'components/Filters/components/FilterModal'
import { DateRangeFilter } from 'components/Filters/fields/DateRangeFilter'
import { RangeFilter } from 'components/Filters/fields/RangeFilter'
import {
  SelectFilter,
  SelectFilterOption,
} from 'components/Filters/fields/SelectFilter'
import { Cuisine50Options } from 'models/cuisine_50'
import apiService from 'services/api'
import { CONTACT_TITLES } from 'utils/constants'
import { FILTER_TITLES } from '../../Filters/filterTitles'

import { SingleSelectFilter } from 'components/Filters/fields/SingleSelectFilter'
import {
  ContactSourceLabels,
  DepartmentLabels,
  EmailCategoryLabels,
  EmailValidityLabels,
  SeniorityLabels,
} from 'models/contacts'
import { FilterIdentifier } from 'models/saved_view'
import { useMemo } from 'react'
import { QuickValueFilter } from '../../Filters/fields/QuickFilter'
import { CONTACTS_COLUMNS } from './contactsColumnInfo'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export const CONTACT_SOURCE_OPTIONS = [
  { value: '0', label: 'Internal' },
  { value: '1', label: 'First Bite' },
]

export default function ContactsFilterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const { data: userOptions } = api.useGetUserOptions(true)
  const { data: stageOptions } = api.useGetSalesStageOptions(true)
  const { data: contactLabelsOption } = api.useGetTagsOptions(true, 'contacts')
  const { data: companyTagsOption } = api.useGetTagsOptions(true, 'products')
  const { data: campaignOptions } = api.useGetCampaignsOptions()
  const { data: companyCatAndSub } = api.useGetCompanyCategoriesAndSub()

  const subCatOptions: SelectFilterOption[] = useMemo(() => {
    return (
      companyCatAndSub?.flatMap((cat) =>
        cat.sub_categories.map((subCat) => ({
          label: subCat.name,
          value: subCat.id.toString(),
        }))
      ) ?? []
    )
  }, [companyCatAndSub])

  const contactsFilterStore = filterStoreRepo.getStore(
    filterIdentifier as FilterIdentifier
  )

  const contactsFilterHelper = createFilterColumnHelper({
    columns: CONTACTS_COLUMNS,
  })

  return (
    <FilterModal store={contactsFilterStore} identifier={filterIdentifier}>
      <FilterGroup title="Quick filters" fontStyle="italic">
        <QuickValueFilter
          {...contactsFilterHelper.retrieveProps('starred_only')}
          valueLabel="Yes"
          predefinedValue={[
            {
              value: '1',
              label: 'Yes',
            },
          ]}
          filterStore={contactsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Contact Type" className="lg:grid-cols-2">
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('title')}
          filterStore={contactsFilterStore}
          options={[
            {
              label: 'No Title',
              value: '-1',
            },
            ...CONTACT_TITLES.map((title) => ({
              label: title,
              value: title,
            })),
          ]}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('contact_labels')}
          options={contactLabelsOption as any}
          filterStore={contactsFilterStore}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('seniority')}
          filterStore={contactsFilterStore}
          options={[
            { value: '', label: 'Select Seniority' },
            ...Object.entries(SeniorityLabels).map(([value, label]) => ({
              value: value.toString(),
              label,
            })),
          ]}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('department')}
          filterStore={contactsFilterStore}
          options={[
            { value: '', label: 'Select Department' },
            ...Object.entries(DepartmentLabels).map(([value, label]) => ({
              value: value.toString(),
              label,
            })),
          ]}
        />
      </FilterGroup>
      <FilterGroup title="Contact Details" className="lg:grid-cols-2">
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('email_type')}
          filterStore={contactsFilterStore}
          options={[
            { value: '-1', label: 'No Email' },
            ...Object.entries(EmailCategoryLabels).map(([value, label]) => ({
              value: value,
              label: label,
            })),
          ]}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('email_validity')}
          filterStore={contactsFilterStore}
          options={[
            { value: 'no_validity', label: 'No Validity' },
            ...Object.entries(EmailValidityLabels).map(([value, label]) => ({
              value: value.toString(),
              label,
            })),
          ]}
        />
        <SingleSelectFilter
          {...contactsFilterHelper.retrieveProps(
            'company_chain_instagram_handle'
          )}
          filterKey={'has_instagram'}
          title={FILTER_TITLES[filterIdentifier].has_instagram}
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
          filterStore={contactsFilterStore}
          hideSearch
        />
        <SingleSelectFilter
          {...contactsFilterHelper.retrieveProps('has_linkedin')}
          filterKey={'has_linkedin'}
          title={FILTER_TITLES[filterIdentifier].has_linkedin}
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
          filterStore={contactsFilterStore}
          hideSearch
        />
        <SingleSelectFilter
          {...contactsFilterHelper.retrieveProps('has_phone_number')}
          options={[
            { value: 'yes', label: 'Yes' },
            { value: 'no', label: 'No' },
          ]}
          filterStore={contactsFilterStore}
          hideSearch
        />
      </FilterGroup>
      <FilterGroup title="Contact Activity">
        <DateRangeFilter
          {...contactsFilterHelper.retrieveProps('created')}
          filterStore={contactsFilterStore}
        />
        <DateRangeFilter
          {...contactsFilterHelper.retrieveProps('modified')}
          filterStore={contactsFilterStore}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('source')}
          filterStore={contactsFilterStore}
          options={[
            ...Object.entries(ContactSourceLabels).map(([value, label]) => ({
              value: value.toString(),
              label,
            })),
          ]}
        />
      </FilterGroup>
      <FilterGroup title="Account Details">
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('account_owners')}
          options={userOptions as any}
          filterStore={contactsFilterStore}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('sales_stages')}
          filterStore={contactsFilterStore}
          options={stageOptions as any}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('origin_campaign')}
          options={campaignOptions as any}
          filterStore={contactsFilterStore}
        />
      </FilterGroup>
      <FilterGroup title="Company Details" className="lg:grid-cols-2">
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('company_type')}
          filterStore={contactsFilterStore}
          filterKey={'sub_category'}
          title={FILTER_TITLES[filterIdentifier].company_type}
          options={subCatOptions}
        />
        <RangeFilter
          {...contactsFilterHelper.retrieveProps('company_size')}
          store={contactsFilterStore}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('company_cuisine_50')}
          filterStore={contactsFilterStore}
          options={Cuisine50Options}
        />
        <SelectFilter
          {...contactsFilterHelper.retrieveProps('company_tags')}
          options={companyTagsOption as any}
          filterStore={contactsFilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
