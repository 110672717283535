import { baseAPICall } from '../../services/api'
import { Campaign } from '../../models/campaign'
import { clientInstance } from '../../utils/http-client'
import { useQuery } from '@tanstack/react-query'
import { RebateOfferTerms } from '../../models/rebateofferterms'

/* -------------
List Rebate Offers
-------------- */
async function getRebateOffers(campaignId: number, signal?: AbortSignal) {
  return baseAPICall<Campaign[]>(() =>
    clientInstance.get(`tradespend/firstbite/offers/${campaignId}`, {
      signal,
    })
  )
}

export const useGetRebateOffers = (
  campaignId: number,
  signal?: AbortSignal,
  disabled = false
) =>
  useQuery({
    queryKey: ['rebate-offers', campaignId],
    queryFn: () => getRebateOffers(campaignId, signal),
    refetchOnMount: false,
    enabled: !disabled,
  })

/* -------------
Get Rebate Suggestion
-------------- */
type GetRebateSuggestionsBody = {
  campaign_id: number
  type: string
  basis: string
  min: number
}

export async function getRebateSuggestions(values: GetRebateSuggestionsBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/get-rebate-suggestions/', values)
  )
}

/* -------------
Get Rebate Suggestion
-------------- */
type CreateRebateOfferBody = {
  name: string
  campaign_id: number
  valid_from: Date
  valid_to: Date
  terms: string
}

export async function createRebateOffer(values: CreateRebateOfferBody) {
  return baseAPICall<RebateOfferTerms>(() =>
    clientInstance.post('tradespend/firstbite/create-rebate-offer/', values)
  )
}
