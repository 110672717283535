import { Badge, Row } from 'react-bootstrap'
import { BsChevronRight, BsGeoAlt, BsTelephone } from 'react-icons/bs'
import { FiEdit2 } from 'react-icons/fi'
import styled from 'styled-components'

export const BreadcrumbGroup = styled.div`
  display: flex;
  align-items: center;
`

export const BreadcrumbBadge = styled(Badge)`
  background-color: #dde5e2 !important;
  color: #06512f;
  font-size: var(--bs-body-font-size);
`

export const CompanyHeader = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const EmptyCompanyLogo = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #d9d9d9;
`

export const CompanyName = styled.div`
  font-weight: 600;
  font-size: 30px;
`

export const CompanyDetails = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #344054;
`

export const CardTitle = styled.div`
  font-size: 20px;
  color: #344054;
`

export const AddressPhoneWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

export const AddressIcon = styled(BsGeoAlt)`
  font-size: 14px;
  color: #5d6b98;
  margin-right: 4px;
`

export const PhoneIcon = styled(BsTelephone)`
  font-size: 14px;
  color: #5d6b98;
  margin-right: 4px;
`

export const RChevronIcon = styled(BsChevronRight)`
  width: 12px;
  height: 12px;
  color: #a0a3a7;
  margin: 0 8px;
`

export const Logo = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 4px;
  object-fit: cover;
  object-position: center;
`

export const BreadcrumbContainer = styled.span`
  display: flex;
  align-items: center;
`

export const BreadcrumbTitle = styled.span`
  color: var(--gray-700, #344054);
  font-weight: 600;
  line-height: 20px;
`

export const UserCreatedContainer = styled.div`
  display: flex;
  align-items: center;
  color: #b98900;
  background-color: #fffcf4;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  line-height: 24px;
  width: fit-content;
  padding: 0 10px;
  border-radius: 10px;
`

export const EditDomainContainer = styled(FiEdit2)`
  cursor: pointer;
  color: #667085;

  &:hover {
    color: #101828;
  }
`

export const TopLeftFirstBiteCol = styled(Row)`
  height: 50%;
  display: flex;
  align-content: flex-start;
  padding-left: 10px;
`

export const BottomLeftFirstBiteCol = styled(Row)`
  height: 50%;
  display: flex;
  align-items: flex-end;
  padding-top: 24px;
  justify-content: center;
`

export const BottomContainerHeader = styled.div`
  color: #475467;
  line-height: 20px;
`

export const BottomContainerText = styled.div`
  color: #101828;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.4px;
`

export const RatingContainer = styled.span`
  display: flex;
  align-items: center;
`

export const ChainCharts = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

export const ChainChartsItem = styled.div`
  height: 230px;
  flex-shrink: 0;
`

export const VerticalDivider = styled.div`
  width: 1px;
  background-color: #eaecf0;
  margin: 0 28px;
  height: 231px;
`

export const PopIncomeInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

export const Label = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #475467;
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ValueBig = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 38px;
  color: #101828;
`

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`

export const SectionItem = styled.div`
  flex-shrink: 0;
  flex: 1;
`
