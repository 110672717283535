import apiService from 'services/api'
import FilterModal, {
  FilterGroup,
} from '../../../Filters/components/FilterModal'
import { SelectFilter } from '../../../Filters/fields/SelectFilter'
import { RangeFilter } from '../../../Filters/fields/RangeFilter'
import { US_STATES } from '../../../../utils/us_states'
import { US_CITIES } from 'utils/us_cities'
import { ZipcodeFilter } from 'components/Filters/fields/ZipcodeFilter'
import { k12LocaleOptions } from '../../../../utils/k12locales'
import { k12Grades } from '../../../../utils/k12grades'
import { SingleSelectFilter } from 'components/Filters/fields/SingleSelectFilter'
import { useEffect, useState } from 'react'

import { FilterIdentifier } from 'models/saved_view'
import { filterStoreRepo } from '../../../Filters/FilterStore'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { K12_COLUMNS } from '../K12ColumnsInfo'

export function K12Filterset({
  filterIdentifier,
}: {
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const k12FilterStore = filterStoreRepo.getStore(filterIdentifier)
  const { data: k12CountiesOptions } = api.useK12CountiesOptions()
  const { pendingValueFilters: pending } = k12FilterStore()
  const { data: campaignOptions } = api.useGetCampaignsOptions('education-k-12')

  const [filteredLowestGrades, setFilteredLowestGrades] = useState(k12Grades)
  const [filteredHighestGrades, setFilteredHighestGrades] = useState(k12Grades)

  const k12FilterHelper = createFilterColumnHelper({
    columns: K12_COLUMNS,
  })

  // This effect ensures that the available options for lowest and highest grade
  // are updated when the selected value changes for gslo or gshi
  useEffect(() => {
    const lowestGrade = pending['gslo']?.[0] ?? null
    const highestGrade = pending['gshi']?.[0] ?? null

    let lowestGradeRank: number | undefined
    let highestGradeRank: number | undefined

    if (lowestGrade) {
      lowestGradeRank = k12Grades.find(
        (grade) => grade.value === lowestGrade.value
      )?.rank
    }

    if (highestGrade) {
      highestGradeRank = k12Grades.find(
        (grade) => grade.value === highestGrade.value
      )?.rank
    }

    setFilteredHighestGrades(
      lowestGrade
        ? k12Grades.filter((grade) => grade.rank >= (lowestGradeRank as number))
        : k12Grades
    )

    setFilteredLowestGrades(
      highestGrade
        ? k12Grades.filter(
            (grade) => grade.rank <= (highestGradeRank as number)
          )
        : k12Grades
    )
  }, [pending])

  return (
    <FilterModal identifier={filterIdentifier} store={k12FilterStore}>
      <CommonFiltersetAccountGroup
        filterStore={k12FilterStore}
        campaignOptions={campaignOptions}
      />
      <FilterGroup title="Location">
        <SelectFilter
          {...k12FilterHelper.retrieveProps('lcity')}
          options={US_CITIES}
          filterStore={k12FilterStore}
        />
        <SelectFilter
          {...k12FilterHelper.retrieveProps('lstate')}
          options={US_STATES}
          filterStore={k12FilterStore}
        />
        <ZipcodeFilter filterStore={k12FilterStore} />
        <SelectFilter
          {...k12FilterHelper.retrieveProps('locale')}
          options={k12LocaleOptions}
          filterStore={k12FilterStore}
        />
        <SelectFilter
          {...k12FilterHelper.retrieveProps('county')}
          options={k12CountiesOptions}
          filterStore={k12FilterStore}
        />
      </FilterGroup>
      <FilterGroup title="District Info">
        <SingleSelectFilter
          {...k12FilterHelper.retrieveProps('gslo')}
          options={filteredLowestGrades}
          filterStore={k12FilterStore}
        />
        <SingleSelectFilter
          {...k12FilterHelper.retrieveProps('gshi')}
          options={filteredHighestGrades}
          filterStore={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('student_count')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('teachers_count')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('operational_schools')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('revenue')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('food_service_expenditures')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('free_lunch')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('reduced_lunch')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('total_supplemented_lunch')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('uncategorized_lunch')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('free_lunch_perc')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('reduced_lunch_perc')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('total_supplemented_lunch_perc')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('uncategorized_lunch_perc')}
          store={k12FilterStore}
        />
        <RangeFilter
          {...k12FilterHelper.retrieveProps('estimated_meals_per_day')}
          store={k12FilterStore}
        />
      </FilterGroup>
    </FilterModal>
  )
}
