import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { z } from 'zod'
import apiService from '../../services/api'
import FbButton from '../FbUI/FbButton'
import { Text } from '../FbUI/Text'
import { NumericInput } from '../FormUtils/NumericInput'
import { Modal } from '../UI/Modal/Modal'

export interface CreateContactRequestJobModalProps {
  campaignId: number
}

const formSchema = z.object({
  max_contacts: z
    .number()
    .min(1, { message: 'Please enter a number between 1 and 5.' })
    .max(5, { message: 'Please enter a number between 1 and 5.' }),
})

type FormSchema = z.infer<typeof formSchema>

export function CreateContactRequesJobFromCampaign(
  props: CreateContactRequestJobModalProps
) {
  const [show, setShow] = useState(false)
  const api = apiService()
  const mutation = api.useRequestContactsFromCampaign()

  const form = useForm<FormSchema>({
    defaultValues: {
      max_contacts: 1,
    },
    resolver: zodResolver(formSchema),
    mode: 'onChange',
  })

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = form

  const handleAccept = async (values: FormSchema) => {
    try {
      await mutation.mutateAsync({
        max_contacts: values.max_contacts,
        campaign_id: props.campaignId,
      })
      toast.success(
        "Contacts successfully requested. Check status in the 'Contact Requests' section of the Contacts page"
      )
      setShow(false)
    } catch (err) {
      toast.error(`Error Requesting Contacts: ${err}.`)
    }
  }

  return (
    <>
      <FbButton onClick={() => setShow(true)}>Request Contacts</FbButton>
      <Modal
        open={show}
        onOpenChange={(open) => !open && setShow(open)}
        title={'Request Contacts for Campaign'}
        onAccept={handleSubmit(handleAccept)}
        acceptButtonText={'Request Contacts'}
        blockAccept={!isValid}
      >
        <Text>
          By submitting this request, you will spend 1 contact credit for every
          successful, valid email found.
        </Text>
        <NumericInput
          label={'How many contacts per company?'}
          control={control}
          fieldName={'max_contacts'}
        />
      </Modal>
    </>
  )
}
