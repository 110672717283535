import to from 'await-to-js'
import { ClientDataTable } from 'components/ClientDataTable/ClientDataTable'
import { TextInput } from 'components/FormUtils/TextInput'
import { addDays } from 'date-fns'
import { Box, DollarSign, ShoppingBag } from 'lucide-react'
import { RebateOfferTerms } from 'models/rebateofferterms'
import { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import FbButton from '../../components/FbUI/FbButton'
import { DateRangePicker } from '../../components/FormUtils/DateRangePicker'
import { NumericInput } from '../../components/FormUtils/NumericInput'
import { RadioButtonSelect } from '../../components/FormUtils/RadioButtonSelect'
import { Modal } from '../../components/UI/Modal/Modal'
import apiService from '../../services/api'

interface CreateRebateOfferModalProps {
  campaignId: number
}

const createRebateOfferFormSchema = z.object({
  name: z.string().min(1),
  type: z.string().min(1),
  basis: z.string().min(1),
  min: z.number(),
  range: z.object({
    from: z.date(),
    to: z.date(),
  }),
})

type CreateRebateOfferForm = z.infer<typeof createRebateOfferFormSchema>

export function CreateRebateOfferModal(props: CreateRebateOfferModalProps) {
  const [open, setOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [loadingTerms, setLoadingTerms] = useState(false)
  const [terms, setTerms] = useState<RebateOfferTerms>()
  const api = apiService()

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
    watch,
  } = useForm<CreateRebateOfferForm>({
    defaultValues: {
      name: '',
      type: 'first-time-offer',
      basis: 'spend',
      range: {
        from: new Date(),
        to: addDays(new Date(), 20),
      },
      min: 1,
    },
  })

  const onGenerateTerms = handleSubmit(async (values) => {
    setLoadingTerms(true)
    const res = await api.getRebateSuggestions({
      campaign_id: props.campaignId,
      min: values.min,
      type: values.type,
      basis: values.basis,
    })
    setTerms(res)
    setStep(1)
  })

  const onSubmitCreate = handleSubmit(async (values) => {
    if (!terms) {
      return
    }

    await to(
      api.createRebateOffer({
        name: values.name,
        terms: JSON.stringify(terms),
        valid_from: values.range.from,
        valid_to: values.range.to,
        campaign_id: props.campaignId,
      })
    )

    reset()
    setOpen(false)
  })

  const basis = watch('basis')

  const summary =
    basis === 'volume'
      ? `Get at least ${watch('min')} units and get $X off the order`
      : `Spending above $${watch('min')}, get $X off the order`
  // function RebateSettings() {
  //   return (
  //     <Fragment>
  //       <RadioButtonSelect
  //         control={control}
  //         name={'type'}
  //         label={'Rebate Type'}
  //         options={[
  //           {
  //             label: 'First Time Offer',
  //             key: 'first-time-offer',
  //             icon: ShoppingBag,
  //           },
  //         ]}
  //       />

  //       <DateRangePicker label={'Period'} control={control} name={'range'} />
  //       {/*
  //       <RadioButtonSelect
  //         control={control}
  //         name={'unit_group'}
  //         label={'Basis'}
  //         options={[
  //           {
  //             label: 'Amount Spent',
  //             key: 'amount',
  //             icon: DollarSign,
  //           },
  //           {
  //             label: 'Volume',
  //             key: 'volume',
  //             icon: Box,
  //           },
  //         ]}
  //       />

  //       {unitGroup === 'volume' && (
  //         <>
  //           <Select
  //             control={control}
  //             name={'unit'}
  //             options={[
  //               {
  //                 label: 'Case',
  //                 value: 'cases',
  //               },
  //               {
  //                 label: 'Lb',
  //                 value: 'lbs',
  //               },
  //               {
  //                 label: 'Gallon',
  //                 value: 'gallons',
  //               },
  //             ]}
  //           />
  //         </>
  //       )}

  //       <div className={'flex flex-row justify-between'}>
  //         <NumericInput
  //           decimalScale={2}
  //           control={control}
  //           fieldName={'min'}
  //           label={unitGroup === 'volume' ? 'Min' : 'Amount'}
  //         />
  //         {unitGroup === 'volume' && (
  //           <NumericInput
  //             decimalScale={2}
  //             control={control}
  //             fieldName={'max'}
  //             label={'Max'}
  //           />
  //         )}
  //       </div> */}

  //       {summary}
  //     </Fragment>
  //   )
  // }

  // function RebateTerms() {
  //   return <></>
  // }

  let hideCancelButton = true
  let acceptButtonText = 'Generate Terms'
  let onAccept = onGenerateTerms
  let cancelButtonText = ''
  let title = 'Set you rebate terms'
  let description = 'set'
  let onCancel = undefined
  let loading = loadingTerms
  if (step === 1) {
    hideCancelButton = false
    acceptButtonText = 'Create Rebate'
    onAccept = onSubmitCreate
    cancelButtonText = 'Back'
    title = 'Create Rebate Offer'
    description = 'Create'
    onCancel = () => setStep(0)
    loading = isSubmitting
  }

  return (
    <Fragment>
      <FbButton onClick={() => setOpen(true)}>Create Rebate Offer</FbButton>
      <Modal
        open={open}
        onOpenChange={(open) => {
          if (!open) {
            reset()
            setOpen(open)
          }
        }}
        loading={loading}
        blockAccept={!isValid}
        preventCloseOnCancel
        title={title}
        description={description}
        onAccept={onAccept}
        acceptButtonText={acceptButtonText}
        hideCancelButton={hideCancelButton}
        cancelButtonText={cancelButtonText}
        onCancel={onCancel}
      >
        {terms == null ? (
          <Fragment>
            <TextInput
              control={control}
              name={'name'}
              label={'Name of the offer'}
            />
            <RadioButtonSelect
              control={control}
              name={'type'}
              label={'Rebate Type'}
              options={[
                {
                  label: 'First Time Offer',
                  key: 'first-time-offer',
                  icon: ShoppingBag,
                },
              ]}
            />

            <DateRangePicker
              label={'Period'}
              control={control}
              name={'range'}
            />

            <RadioButtonSelect
              control={control}
              name={'basis'}
              label={'Basis'}
              options={[
                {
                  label: 'Amount Spent',
                  key: 'spend',
                  icon: DollarSign,
                },
                {
                  label: 'Volume',
                  key: 'volume',
                  icon: Box,
                },
              ]}
            />

            <div className={'flex flex-row justify-between'}>
              <NumericInput
                decimalScale={2}
                control={control}
                fieldName={'min'}
                label={'Amount'}
              />
            </div>

            {summary}
          </Fragment>
        ) : (
          <Fragment>
            <ClientDataTable
              columns={[
                {
                  accessorKey: 'company_name',
                  header: 'Company Name',
                },
                {
                  header: 'Amount',
                  id: 'min_units',
                  cell: (info) => {
                    const value = info.row.original.products[0].tiers[0].min
                    const unit = info.row.original.products[0].unit
                    if (basis == 'spend') {
                      return '$' + value
                    } else {
                      return value + ' ' + unit
                    }
                  },
                  size: 100,
                },
                {
                  header: 'Discount',
                  id: 'discount',
                  cell: (info) => {
                    return '$' + info.row.original.products[0].tiers[0].discount
                  },
                  size: 100,
                },
              ]}
              data={Object.values(terms)}
              enableSorting
            />
          </Fragment>
        )}
      </Modal>
    </Fragment>
  )
}
