import { useCallback, useEffect, useState } from 'react'
import apiService from 'services/api'
import { ALL_COUNTRIES } from 'utils/countries'
import { US_STATES } from '../../../utils/us_states'
import { filterStoreRepo } from '../../Filters/FilterStore'
import FilterModal, { FilterGroup } from '../../Filters/components/FilterModal'
import { SelectFilter } from '../../Filters/fields/SelectFilter'

import { FilterIdentifier } from '../../../models/saved_view'
import { CommonFiltersetAccountGroup } from 'components/DataExpansionComponents/Common/CommonTable/CommonFiltersetAccountGroup'
import { COMPANIES_COLUMNS } from './companiesColumnInfo'
import { createFilterColumnHelper } from 'components/DataExpansionComponents/Common/commonColumnHelpers'

export function CompaniesFilterset(props: {
  subCategoryId: number
  filterIdentifier: FilterIdentifier
}) {
  const api = apiService()
  const { data: cityOptions } = api.useGetUnmatchedCompanyCities(
    props.subCategoryId
  )
  const [usSpecificFiltersVisible, setUsSpecificFiltersVisible] =
    useState<boolean>(true)

  const companiesFilterStore = filterStoreRepo.getStore(props.filterIdentifier)

  const { pendingValueFilters, clearPendingValueFilter } =
    companiesFilterStore()

  const clearUsFilters = useCallback(() => {
    // NOTE: since we are fetching all distinct unmatched company cities
    // we do not need to hide/clear cities filter if non-US countries are selected
    if (pendingValueFilters.state) {
      clearPendingValueFilter('state')
    }
  }, [pendingValueFilters, clearPendingValueFilter])

  useEffect(() => {
    const selectedCountries = pendingValueFilters?.country?.map((f) => f.value)
    const showUsSpecificFilters = !(
      selectedCountries?.length > 0 && !selectedCountries.includes('US')
    )

    if (showUsSpecificFilters !== usSpecificFiltersVisible) {
      setUsSpecificFiltersVisible(showUsSpecificFilters)
    }

    if (!showUsSpecificFilters) {
      clearUsFilters()
    }
  }, [pendingValueFilters, clearUsFilters, usSpecificFiltersVisible])

  const companiesFilterHelper = createFilterColumnHelper({
    columns: COMPANIES_COLUMNS,
  })

  return (
    <FilterModal
      identifier={props.filterIdentifier}
      store={companiesFilterStore}
    >
      <CommonFiltersetAccountGroup filterStore={companiesFilterStore} />
      <FilterGroup title="Location">
        <SelectFilter
          {...companiesFilterHelper.retrieveProps('country')}
          options={ALL_COUNTRIES}
          filterStore={companiesFilterStore}
        />
        <SelectFilter
          {...companiesFilterHelper.retrieveProps('city')}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          options={cityOptions as any}
          filterStore={companiesFilterStore}
        />
        {usSpecificFiltersVisible && (
          <SelectFilter
            {...companiesFilterHelper.retrieveProps('state')}
            options={US_STATES}
            filterStore={companiesFilterStore}
          />
        )}
      </FilterGroup>
    </FilterModal>
  )
}
