import React, { useMemo } from 'react'
import apiService from '../../services/api'
import dialogConfirm from '../../components/dialogConfirm'
import { ColumnDef } from '@tanstack/react-table'
import { ActionButtons } from '../../components/Buttons/ActionButtons'
import { OverflownText } from '../../components/OverflownText'
import { Campaign } from '../../models/campaign'
import { CampaignsCell } from '../../components/FbUI/StagePill'
import { ClientDataTable } from '../../components/ClientDataTable/ClientDataTable'
import { DataTableContainer } from '../../components/DataTable/UI'

export function CampaignsTable(props: {
  tableKey?: string
  onOpenCampaign?: (campaign: Campaign) => void
  onEditCampaign?: (campaign: Campaign) => void
}) {
  const api = apiService()
  const { data: campaignData, isFetching } = api.useGetCampaigns()
  const deleteCampaignMutation = api.useDeleteCampaign()

  async function handleRowDelete(index: number) {
    const id = campaignData?.[index]?.id
    if (!id) return

    if (
      await dialogConfirm(
        'Are you sure you want to delete this campaign?',
        'Delete Campaign'
      )
    ) {
      await deleteCampaignMutation.mutateAsync(id)
    }
  }

  const columns = useMemo<ColumnDef<Campaign, any>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Campaign Name',
        size: 150,
        cell: (info) => {
          return <CampaignsCell campaigns={[info.row.original]} />
        },
      },
      {
        accessorKey: 'description',
        header: 'Description',
        size: 200,
      },
      {
        accessorKey: 'type.name',
        header: 'Type',
        size: 150,
      },
      {
        accessorKey: 'contact_company_count',
        header: 'Opps Assigned',
        size: 100,
      },
      {
        accessorKey: 'deal_count',
        header: 'Deals Count',
        size: 100,
      },
      {
        accessorKey: 'contact_count',
        header: 'Contacts Count',
        size: 100,
      },
      {
        accessorKey: 'product.name',
        header: 'Product',
        size: 300,
        cell: (info) => {
          const value = info.getValue()

          return value ? (
            <OverflownText maxLines={2}>{value}</OverflownText>
          ) : (
            'N/A'
          )
        },
      },
      {
        id: 'edit',
        header: 'Actions',
        size: 130,
        meta: { rightAlign: true },
        cell: ({ row }) => (
          <ActionButtons
            onDelete={() => handleRowDelete(row.index)}
            onEdit={() => props.onEditCampaign?.(row.original)}
            onSee={
              props.onOpenCampaign
                ? () => props?.onOpenCampaign?.(row.original)
                : undefined
            }
          />
        ),
      },
    ],
    [campaignData]
  )
  return (
    <DataTableContainer key={props.tableKey + '-container'}>
      <ClientDataTable
        loading={isFetching}
        height={'100%'}
        data={campaignData ?? []}
        columns={columns}
      />
    </DataTableContainer>
  )
}
