import { getAllFilterTitles } from 'components/DataExpansionComponents/Common/commonColumnHelpers'
import { FilterIdentifier } from '../../models/saved_view'
import { COMMON_COLUMNS } from 'components/DataExpansionComponents/Common/CommonColumnsInfo'
import { UNIVERSITY_COLUMNS } from 'components/DataExpansionComponents/Universities/universityColumnsInfo'
import { K12_COLUMNS } from 'components/DataExpansionComponents/K12/K12ColumnsInfo'
import { HOSPITAL_COLUMNS } from 'components/DataExpansionComponents/Hospitals/hospitalColumnsInfo'
import { CHAIN_COLUMNS } from 'components/DataExpansionComponents/Chains/chainColumnsInfo'
import { DOORS_COLUMNS } from 'components/DataExpansionComponents/Doors/doorsColumnsInfo'
import { CONTACTS_COLUMNS } from 'components/Tables/ContactsTable/contactsColumnInfo'
import { COMPANIES_COLUMNS } from 'components/Tables/CompaniesTable/companiesColumnInfo'
import { DEAL_COLUMNS } from 'components/DataExpansionComponents/Deals/dealsColumnInfo'
import { ACTIVITY_V2_COLUMNS } from 'components/DataExpansionComponents/Activity/activityV2ColumnInfo'
import { DEALS_PIPELINE_COLUMNS } from 'components/DataExpansionComponents/Deals/dealsPipelineColumnInfo'

const allCommonFilterTitles: Record<string, string> = {
  ...getAllFilterTitles({ columns: COMMON_COLUMNS }),
}

const originalFilterTitles: Record<FilterIdentifier, Record<string, string>> = {
  CNU_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: UNIVERSITY_COLUMNS }),
  },
  K12_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: K12_COLUMNS }),
  },
  DOORS_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: DOORS_COLUMNS }),
  },
  DEALS_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: DEAL_COLUMNS }),
  },
  DEALS_PIPELINE_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: DEALS_PIPELINE_COLUMNS }),
  },
  CONTACTS_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: CONTACTS_COLUMNS }),
  },
  COMPANIES_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: COMPANIES_COLUMNS }),
  },
  CHAINS_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: CHAIN_COLUMNS }),
  },
  ACTIVITY_TABLE_V2: {
    ...getAllFilterTitles({ columns: ACTIVITY_V2_COLUMNS }),
  },
  HOSPITAL_TABLE: {
    ...allCommonFilterTitles,
    ...getAllFilterTitles({ columns: HOSPITAL_COLUMNS }),
  },
}

// Ugly hack, sorry
export const FILTER_TITLES = new Proxy(originalFilterTitles, {
  get(target, prop: string) {
    if (prop.startsWith('companies-')) {
      return target['COMPANIES_TABLE']
    }
    if (prop.startsWith('CHAINS_TABLE')) {
      return target['CHAINS_TABLE']
    }
    if (prop.startsWith('DEALS_TABLE')) {
      return target['DEALS_TABLE']
    }
    if (prop.startsWith('CNU_TABLE')) {
      return target['CNU_TABLE']
    }
    if (prop.startsWith('K12_TABLE')) {
      return target['K12_TABLE']
    }
    if (prop.startsWith('CONTACTS_TABLE')) {
      return target['CONTACTS_TABLE']
    }
    if (prop.startsWith('HOSPITAL_TABLE')) {
      return target['HOSPITAL_TABLE']
    }
    return target[prop as keyof typeof target]
  },
})
