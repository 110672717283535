import { useDataTableContext } from 'components/DataTable'
import apiService from 'services/api'
import lodash from 'lodash'
import confirm from 'components/dialogConfirm'
import { queryClient } from 'services/queryClient'

interface BulkDealsDeleterProps {
  data: any[]
  accessorKey: string
  tableQueryKey: string[]
  filterParams: any
}

export function useBulkDealsDeleter(props: BulkDealsDeleterProps) {
  const api = apiService()

  const {
    state: { rowSelection, isAllRowsSelected },
  } = useDataTableContext()

  async function handleSubmit() {
    const selectedRows = props.data?.filter((_, i) => {
      if (isAllRowsSelected) {
        return !rowSelection?.[i]
      }
      return !!rowSelection?.[i]
    })

    const selectedDealIDs = selectedRows?.map((r: any) =>
      lodash.get(r, props.accessorKey)
    ) as number[]

    const confirmed = await confirm(
      `Confirm deleting ${selectedDealIDs.length} deals?`,
      'Delete deals'
    )

    if (!confirmed) {
      return
    }

    await api.bulkDeleteDeals(selectedDealIDs, isAllRowsSelected)
    queryClient.refetchQueries({ queryKey: props.tableQueryKey })
  }

  return handleSubmit
}
