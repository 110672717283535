import React, { useMemo } from 'react'
import apiService from '../../services/api'
import { ColumnDef } from '@tanstack/react-table'
import { ClientDataTable } from '../../components/ClientDataTable/ClientDataTable'

export function RebateOffersTable(props: { campaignId: number }) {
  const api = apiService()
  const { data: campaignData, isFetching } = api.useGetRebateOffers(
    props.campaignId
  )

  const columns = useMemo<ColumnDef<any, any>[]>(
    () => [
      {
        accessorKey: 'code',
        header: 'Rebate Code',
        size: 150,
      },
      {
        accessorKey: 'email',
        header: 'Email',
        size: 150,
      },
      {
        header: 'Terms',
        cell: (info) => {
          return (
            <pre>
              {JSON.stringify(info.row.original.terms, null, 2).substring(
                0,
                500
              )}
            </pre>
          )
        },
      },
    ],
    [campaignData]
  )

  return (
    <ClientDataTable
      loading={isFetching}
      height={'calc(100% - 50px)'}
      stickyLastColumn={true}
      data={campaignData ?? []}
      columns={columns}
    />
  )
}
